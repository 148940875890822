<template>
    <div style="clear:both;">
        <span class="Label">{{title}}</span>
        <div class="SubItems CheckBoxList ClearFix">
            <slot></slot>
        </div>
    </div>
</template>

<script>

export default {
  name: 'ImisCheckBoxList',
  props: {
    title: String
  },
  data () {
    return {}
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
