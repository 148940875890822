<template>
    <imis-panel-field v-slot="slot"
        :caption="caption"
        :positionCaption="positionCaption"
        :size="size"
        :info="info"
        :autoWidth="autoWidth"
        :disabled="disabled"
        :required="required"
        :valid="valid"
        :error="error" >
        <select
            :id="slot.uid"
            :class="slot.size"
            :disabled="slot.disabled"
            :value="value"
            @change="$emit('change', $event.target.value)">
            <option v-if="placeholder !== ''" value="" disabled selected hidden>{{placeholder}}</option>
            <slot></slot>
        </select>
    </imis-panel-field>
</template>

<script>
import ImisPanelField from './ImisPanelField.vue'

export default {
  name: 'ImisDropDown',
  components: {
    ImisPanelField
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: String,
    caption: String,
    positionCaption: String,
    size: String,
    info: String,
    autoWidth: Boolean,
    disabled: Boolean,
    required: Boolean,
    valid: Boolean,
    error: String,
    placeholder: String
  },
  data () {
    return {}
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
