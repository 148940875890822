<template>
    <imis-panel-field v-slot="slot"
        :caption="caption"
        :positionCaption="positionCaption"
        :size="size"
        :info="info"
        :autoWidth="autoWidth"
        :disabled="disabled"
        :required="required"
        :valid="valid"
        :error="error" >
        <div :class="'fileUpload ' + slot.size + (disabled ? ' disabled' : '')">
            <div :class="'drop' + (dropState !== '' ? ' ' + dropState : '')"
                @dragleave.stop.prevent="if (!disabled) onDragLeave($event)"
                @dragenter.stop.prevent="if (!disabled) onDragEnter($event)"
                @dragover.stop.prevent="if (!disabled) onDragOver($event)"
                @drop.stop.prevent="if (!disabled) onDrop($event, 'drop')">
                {{placeholder}}
                <div class="fileInputWrap">
                    <p>or</p>
                    <input :id="slot.uid" type="file" @change="onDrop($event, 'file')" :disabled="disabled" />
                    <label :for="slot.uid" :class="'TextButton' + (disabled ? ' disabled' : '')">Browse</label>
                </div>
            </div>
            <p v-if="validExtensions.length > 0" class="Info">Allowed file type(s): {{validExtensions.join(', ').toUpperCase()}}</p>
            <slot></slot>
        </div>
    </imis-panel-field>
</template>

<script>

import mtapi from '../../mtapi'
import ImisPanelField from './ImisPanelField.vue'

export default {
  name: 'ImisFileUpload',
  components: {
    ImisPanelField
  },
  props: {
    caption: String,
    positionCaption: String,
    size: { type: String, default: 'XL' },
    info: String,
    autoWidth: Boolean,
    disabled: Boolean,
    required: Boolean,
    valid: Boolean,
    error: String,
    placeholder: { type: String, default: 'Drag and drop file' },
    allowedFileTypes: { type: String, default: '' }
  },
  computed: {
    validExtensions () {
      if (this.allowedFileTypes !== '') { return this.allowedFileTypes.toUpperCase().split(',') } else { return [] }
    }
  },
  data () {
    return {
      dropState: ''
    }
  },
  created () {
  },
  methods: {
    onDragLeave (event) {
      this.dropState = ''
      event.dataTransfer.dropEffect = 'copy'
    },
    onDragEnter (event) {
      this.dropState = 'dropOver'
      event.dataTransfer.dropEffect = 'copy'
    },
    onDragOver (event) {
      this.dropState = 'dropOver'
      event.dataTransfer.dropEffect = 'copy'
    },
    onDrop (event, eventType) {
      let files
      this.dropState = 'dropActive'
      if (eventType === 'drop') {
        files = event.dataTransfer.files
      } else {
        files = event.target.files
      }
      const reader = new FileReader()
      const file = files[0]
      reader.file = file
      reader.onload = (event) => {
        let data = null
        if (mtapi.readAsBinaryStringSupported) { data = event.target.result } else { data = this.fixdata(event.target.result) }
        this.$emit('upload', {
          name: event.target.file.name,
          type: event.target.file.type,
          size: event.target.file.size,
          data: data
        })
        setTimeout(() => {
          this.dropState = ''
        }, 500)
      }
      if (this.validExtensions === '' || this.validExtensions.includes(file.name.split('.').pop().toUpperCase())) {
        if (mtapi.readAsBinaryStringSupported) { reader.readAsBinaryString(file) } else { reader.readAsArrayBuffer(file) }
      } else {
        this.dropState = 'dropError'
        this.$emit('error', {
          msg: `File type ${file.name.split('.').pop().toUpperCase()} is not allowed`
        })
        setTimeout(() => {
          this.dropState = ''
        }, 500)
      }
    },
    fixdata (data) {
      var o = ''; var l = 0; var w = 10240
      for (; l < data.byteLength / w; ++l) o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w, l * w + w)))
      o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w)))
      return o
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .fileUpload {
        display:inline-block;
        vertical-align: top;
    }
    .fileUpload.disabled .drop {
        border-color:#c0c0c0;
        color:#c0c0c0;
        background-color: #eeeeee;
    }
    .drop {
        border: 2px dashed #e0e0e0;
        border-radius: 5px;
        padding: 25px;
        text-align: center;
        font-size: 1.0em;
        font-weight: normal;
        color:#606060;
        transition: color .3s linear,
                    border .3s linear;
    }

    .dropOver {
        background-color: #BDE5F8;  //86%
        border-color: #23a9e8;      //52%
    }

    .dropActive {
        background-color: #cceccc; //86%
        border-color: #44ba44;   //50%
    }

    .dropError {
        background-color: #ffbebe;
        border-color: #ff0303;
    }

    .browse {
        text-align: center;
        margin: 1.25em 0 0 0;
    }

    .drop.dropOver .fileInputWrap {
        pointer-events: none;
    }

    .fileInputWrap p {
        font-size:0.75em;
        margin: 0.5em 0;
    }

    .fileInputWrap input[type="file"] {
        display:none;
    }

    .fileInputWrap label.TextButton {
        margin: 0;
        display: inline-block;
        float:none;
        padding: 0.5em;
    }
</style>
