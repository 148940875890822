<template>
    <div class="panel">
        <div class="panel-heading" v-html="titleMarkup">
        </div>
        <div class="panel-body-container">
            <div class="panel-body">
                <div>
                    <slot></slot>
                </div>
                <div :class="buttonBarClass">
                    <slot name="buttonbar"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'ImisPanel',
  components: {
  },
  props: {
    title: String,
    headingLevel: { type: String, default: '2' },
    buttonAlign: { type: String, default: '' }
  },
  data () {
    return {}
  },
  computed: {
    titleMarkup () {
      const result = `<h${this.headingLevel} class="panel-title">${this.title}</h${this.headingLevel}>`
      return result
    },
    buttonBarClass () {
      return this.buttonAlign.toLowerCase() === 'left' ? '' : 'FloatRight'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
