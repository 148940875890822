<template>
    <imis-panel-field v-slot="slot"
        :caption="caption"
        :positionCaption="positionCaption"
        :size="size"
        :info="info"
        :autoWidth="autoWidth"
        :disabled="disabled"
        :required="required"
        :valid="valid"
        :error="error" >
        <div :class="slot.size">
            <monaco-editor
                ref="monaco"
                :id="slot.uid"
                :value="value"
                @input="$emit('input', $event)"
                class="editor"
                :language="language"
                :theme="theme"
                :options="options"
                @editorDidMount="$emit('editorDidMount', $event)"
            />
        </div>
    </imis-panel-field>
</template>

<script>
import ImisPanelField from './ImisPanelField.vue'
import MonacoEditor from 'vue-monaco-cdn'

export default {
  name: 'ImisCodeEditor',
  components: {
    ImisPanelField,
    MonacoEditor
  },
  props: {
    value: String,
    caption: String,
    positionCaption: String,
    size: { type: String, default: 'full' },
    info: String,
    autoWidth: Boolean,
    disabled: Boolean,
    required: Boolean,
    valid: Boolean,
    error: String,
    placeholder: String,
    language: { type: String, default: 'javascript' },
    theme: { type: String, default: 'vs-dark' },
    options: {
      type: Object,
      default: () => ({
        automaticLayout: true,
        minimap: { enabled: false }
      })
    }
  },
  data () {
    return {}
  },
  methods: {
    getMonaco () {
      return this.$refs.monaco.getMonaco()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .editor {
        min-height: 400px;
    }
</style>
