<template>
    <imis-panel-field v-slot="slot"
        :caption="caption"
        :positionCaption="positionCaption"
        :size="size"
        :info="info"
        :autoWidth="autoWidth"
        :disabled="disabled"
        :required="required"
        :valid="valid"
        :error="error" >
        <input type="radio"
            :id="slot.uid"
            :class="slot.size"
            :disabled="slot.disabled"
            :checked="isChecked"
            :value="value"
            @change="update">
    </imis-panel-field>
</template>

<script>
import ImisPanelField from './ImisPanelField.vue'

export default {
  name: 'ImisRadioButton',
  components: {
    ImisPanelField
  },
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    value: [String, Boolean, Number],
    modelValue: null,
    caption: String,
    positionCaption: { type: String, default: 'Right' },
    size: String,
    info: String,
    autoWidth: Boolean,
    disabled: Boolean,
    required: Boolean,
    valid: Boolean,
    error: String
  },
  data () {
    return {}
  },
  computed: {
    isChecked () {
      if (this.modelValue instanceof Array) { return this.modelValue.includes(this.value) } else { return this.modelValue === this.value }
    }
  },
  methods: {
    update (event) {
      this.$emit('change', this.value)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
