<template>
    <imis-panel-field v-slot="slot"
        :caption="caption"
        :positionCaption="positionCaption"
        :size="size"
        :info="info"
        :autoWidth="autoWidth"
        :disabled="disabled"
        :required="required"
        :valid="valid"
        :error="error" >
        <div :class="slot.size">
            <multiselect
            :removeLabel="true" 
                :id="slot.uid"
                :disabled="disabled"
                v-bind="$attrs"
                v-on="$listeners">
                <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"><slot :name="slot" v-bind="scope"/></template>
                <template slot="option" slot-scope="{ option }">
                  <div class="option-container">
                    <img :src="option.ThumbnailPath" alt="Option Image" class="option-image" />
                    <span>{{ option.FullName }}</span>
                  </div>
                  <!-- <div>
                
                    <img :src="option.ThumbnailPath" alt="Option Image" class="option-image" />
                    <span>{{ option.FullName }}</span>
                  </div> -->
                </template>
            </multiselect>
        </div>
    </imis-panel-field>
</template>

<script>
import ImisPanelField from './ImisPanelField.vue'
import Multiselect from 'vue-multiselect'

export default {
  name: 'ImisMultiSelect',
  components: {
    ImisPanelField,
    Multiselect
  },
  props: {
    disabled: Boolean,
    caption: String,
    positionCaption: String,
    size: String,
    info: String,
    autoWidth: Boolean,
    required: Boolean,
    valid: Boolean,
    error: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.multiselect {
  line-height:normal;
}
.multiselect .multiselect__input {
    border: 0;
}
</style>

<style >

.multiselect__content{
  display: grid!Important;
  grid-template-columns: 1fr 1fr 1fr;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
