<template>
    <div :class="('PanelField ' + position)">
        <label :class="labelCssClass" v-if="position !== 'Right'" :for="uid">{{ caption }}</label>
        <div class="PanelFieldValue">
            <slot :uid="uid" :size="inputSize" :disabled="disabled"></slot>
            <span v-if="error !== undefined && !valid && position !== 'Right'" class="Important ValidationError">{{ error }}</span>
            <p v-if="info !== undefined" class="Info">{{ info }}</p>
        </div>
        <label :class="labelCssClass" v-if="position === 'Right'" :for="uid">{{ caption }}</label>
        <span v-if="error !== undefined && !valid && position === 'Right'" class="Important ValidationError">{{ error }}</span>
    </div>
</template>

<script>
import mtapi from '../../mtapi/core'

export default {
  name: 'ImisPanelField',
  props: {
    caption: String,
    positionCaption: { type: String, default: 'Top' },
    size: { type: String, default: 'xl' },
    info: String,
    autoWidth: Boolean,
    disabled: Boolean,
    required: Boolean,
    valid: Boolean,
    error: String
  },
  data () {
    return {
      uid: String
    }
  },
  computed: {
    labelCssClass () {
      let cssClass = this.autoWidth && this.position === 'Left' ? 'AutoWidth' : ''
      cssClass += this.required ? ' Required' : ''
      cssClass += this.disabled ? ' aspNetDisabled' : ''
      cssClass += ' unselectable'
      return cssClass.trim()
    },
    position () {
      return this.capitalise(this.positionCaption)
    },
    inputSize () {
      switch (this.size.toLowerCase()) {
        case 'xxs': return 'InputXXSmall'
        case 'xs': return 'InputXSmall'
        case 's': return 'InputSmall'
        case 'm': return 'InputMedium'
        case 'l': return 'InputLarge'
        case 'xl': return 'InputXLarge'
        case 'xxl': return 'InputXXLarge'
        case 'full': return 'FullWidth'
        default: return ''
      }
    }
  },
  created () {
    this.uid = mtapi.uid()
  },
  methods: {
    capitalise (s) {
      if (typeof s !== 'string') return ''
      return s.charAt(0).toUpperCase() + s.slice(1)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    span.Error.ValidationError {
        display:inline;
    }
    .unselectable {
        user-select: none;
    }
</style>
