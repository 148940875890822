<template>
    <imis-panel-field v-slot="slot"
        :caption="caption"
        :positionCaption="positionCaption"
        :size="size"
        :info="info"
        :autoWidth="autoWidth"
        :disabled="disabled"
        :required="required"
        :valid="valid"
        :error="error" >
        <input type="text"
            :id="slot.uid"
            :class="slot.size"
            :disabled="slot.disabled"
            :placeholder="placeholder"
            :value="path"
            readonly />
            <a href=""
                @click.prevent="if (!slot.disabled) open()"
                :class="'MarginLeft' + (slot.disabled ? ' aspNetDisabled' : '')"
            >select</a>
    </imis-panel-field>
</template>

<script>
import mtapi from '../../mtapi'
import ImisPanelField from './ImisPanelField.vue'

export default {
  name: 'ImisObjectBrowser',
  components: {
    ImisPanelField
  },
  props: {
    value: [String, Array],
    caption: String,
    positionCaption: String,
    size: String,
    info: String,
    autoWidth: Boolean,
    disabled: Boolean,
    required: Boolean,
    valid: Boolean,
    error: String,
    placeholder: String,
    windowTitle: { type: String, default: 'Document Browser' },
    documentPath: { type: String, default: '@/' },
    rootFolder: { type: String, default: '' },
    typeFilter: { type: String, default: '' },
    multiSelect: { type: Boolean, default: false },
    publishedOnly: { type: Boolean, default: false },
    showRecycleBin: { type: Boolean, default: false },
    hideAddress: { type: Boolean, default: false },
    showMenu: { type: Boolean, default: false },
    showMenuPublish: { type: Boolean, default: false },
    showMenuVersions: { type: Boolean, default: false },
    showMenuRun: { type: Boolean, default: false },
    showMenuPreview: { type: Boolean, default: false },
    hideMenuNew: { type: Boolean, default: false },
    hideMenuEdit: { type: Boolean, default: false },
    hideMenuOrganize: { type: Boolean, default: false }
  },
  data () {
    return {
      path: ''
    }
  },
  computed: {
    urlParams () {
      let urlParamString = `DocumentPath=${encodeURIComponent(this.documentPath)}`
      if (this.rootFolder !== '') { urlParamString += `&iRootFolder=${encodeURIComponent(this.rootFolder)}` }
      if (this.typeFilter !== '') { urlParamString += `&TypeFilter=${encodeURIComponent(this.typeFilter.toUpperCase())}` }
      urlParamString += `&ShowRecycleBin=${this.showRecycleBin}`
      if (this.hideAddress) { urlParamString += `&ShowAddress=${!this.hideAddress}` }
      if (this.showMenu) { urlParamString += `&ShowMenu=${this.showMenu}` }
      if (this.showMenu) {
        urlParamString += `&ShowMenuPublish=${this.showMenuPublish}`
        urlParamString += `&ShowMenuVersions=${this.showMenuVersions}`
        urlParamString += `&ShowMenuRun=${this.showMenuRun}`
        urlParamString += `&ShowMenuPreview=${this.showMenuPreview}`
        urlParamString += `&ShowMenuNew=${!this.hideMenuNew}`
        urlParamString += `&ShowMenuEdit=${!this.hideMenuEdit}`
        urlParamString += `&ShowMenuOrganize=${!this.hideMenuOrganize}`
      }
      return urlParamString
    }
  },
  created () {
    if (this.multiSelect && Array.isArray(this.value) && this.value.length > 0 && this.value.reduce((result, value) => { return result && mtapi.isGuid(value) }, true)) {
      this.path = ''
      const requests = []
      this.value.forEach(value => {
        requests.push(mtapi.getDocumentByVersionKey(value))
      })
      mtapi.axios.all(requests).then(responses => {
        responses.forEach(response => {
          this.path += this.path === '' ? response.Path : ',' + response.Path
        })
      })
    } else {
      if (mtapi.isGuid(this.value)) {
        mtapi.getDocumentByVersionKey(this.value).then(response => {
          this.path = response.Path
        })
      }
    }
  },
  methods: {
    open () {
      window.OpenObjectBrowser(this.urlParams, this.callback, !this.multiSelect, this.publishedOnly, null, this.windowTitle)
    },
    callback (result, cancel) {
      if (!(cancel.get_argument() !== null && cancel.get_argument().cancel) && result.SelectedDocumentPath !== undefined && result.result !== this.value) {
        if (this.multiSelect) {
          this.path = ''
          const requests = []
          result.result.split(',').forEach(value => {
            requests.push(mtapi.getDocumentByVersionKey(value))
          })
          mtapi.axios.all(requests).then(responses => {
            responses.forEach(response => {
              this.path += this.path === '' ? response.Path : ',' + response.Path
            })
            this.$emit('input', result.result.split(','), this.path.split(','))
          })
        } else {
          mtapi.getDocumentByVersionKey(result.result).then(response => {
            this.path = response.Path
            this.$emit('input', result.result, this.path)
          })
        }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
