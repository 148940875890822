<template>
    <imis-panel-field v-slot="slot"
        :caption="caption"
        :positionCaption="positionCaption"
        :size="size"
        :info="info"
        :autoWidth="autoWidth"
        :disabled="disabled"
        :required="required"
        :valid="valid"
        :error="error" >
        <input type="checkbox"
            :id="slot.uid"
            :class="slot.size"
            :disabled="slot.disabled"
            :checked="isChecked"
            :value="value"
            @change="update"
             />
    </imis-panel-field>
</template>

<script>
import ImisPanelField from './ImisPanelField.vue'

export default {
  name: 'ImisCheckBox',
  components: {
    ImisPanelField
  },
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    value: [String, Boolean, Number],
    modelValue: [Boolean, Array],
    caption: String,
    positionCaption: { type: String, default: 'Right' },
    size: String,
    info: String,
    autoWidth: Boolean,
    disabled: Boolean,
    required: Boolean,
    valid: Boolean,
    error: String,
    trueValue: { default: true },
    falseValue: { default: false }
  },
  data () {
    return {}
  },
  computed: {
    isChecked () {
      if (this.modelValue instanceof Array) { return this.modelValue.includes(this.value) } else { return this.modelValue === this.trueValue }
    }
  },
  methods: {
    update (event) {
      const checked = event.target.checked

      if (this.modelValue instanceof Array) {
        const value = [...this.modelValue]
        if (checked) { value.push(this.value) } else { value.splice(value.indexOf(this.value), 1) }
        this.$emit('change', value)
      } else {
        this.$emit('change', checked ? this.trueValue : this.falseValue)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
